// import { callApi } from "@/services/index";
import { mockData } from "./mockData";

type ImpactLevels = "Low" | "Medium" | "High";

export interface IEconomicCalendarDto {
    date: string;
    title: string;
    timestamp: string;
    actual: number;
    estimate: number;
    previous: number;
    impact: ImpactLevels;
    country: string;
}

export const economicCalendarApi = {
    getData: async () => {
        return Promise.resolve(mockData);

        //     const response = await callApi({
        //         endpoint: "/api/economic-calendar",
        //         method: "GET",
        //     });

        //     if (response?.isFailed) {
        //         return [];
        //     }

        //     return response as Promise<IEconomicCalendarDto[]>;
    },

    // ...
};
