// import { callApi } from "@/services/index";
import { TableTypes } from "@/components/Core/Table";
import { mockData } from "./mockData";

export interface IPivotPointsDto {
    name: string;
    s3: number;
    s2: number;
    s1: number;
    pivotPoints: number;
    r1: number;
    r2: number;
    r3: number;
}

export interface IMovingAveragessDto {
    period: string;
    simple: number;
    exponential: number;
    action: string;
}

export interface ITechnicalIndicatorsDto {
    name: string;
    value: number;
    action: string;
}

export const technicalAnalysisApi = {
    getPivotPointsData: async (): Promise<IPivotPointsDto[]> => {
        return Promise.resolve(mockData[TableTypes.pivotPoints]);

        //     const response = await callApi({
        //         endpoint: "/api/technical-indicators",
        //         method: "GET",
        //     });

        //     if (response?.isFailed) {
        //         return [];
        //     }

        //     return response as Promise<IEconomicCalendarDto[]>;
    },

    getMovingAveragesData: async (): Promise<IMovingAveragessDto[]> => {
        return Promise.resolve(mockData[TableTypes.movingAverages]);

        //     const response = await callApi({
        //         endpoint: "/api/moving-averages",
        //         method: "GET",
        //     });

        //     if (response?.isFailed) {
        //         return [];
        //     }

        //     return response as Promise<IEconomicCalendarDto[]>;
    },

    getTechnicalIndicatorsData: async (): Promise<ITechnicalIndicatorsDto[]> => {
        return Promise.resolve(mockData[TableTypes.technicalIndicators]);

        //     const response = await callApi({
        //         endpoint: "/api/moving-averages",
        //         method: "GET",
        //     });

        //     if (response?.isFailed) {
        //         return [];
        //     }

        //     return response as Promise<IEconomicCalendarDto[]>;
    },
};
