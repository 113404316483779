import { FC } from "react";

interface ImpactIconProps {
    level: "Low" | "Medium" | "High";
}

export const ImpactIcon: FC<ImpactIconProps> = ({ level }) => {
    return (
        <svg width="34" height="10" viewBox="0 0 34 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.51059e-05 7.85007C2.7043e-05 6.91462 0.64841 6.10411 1.56104 5.89877L8.78049 4.27439C9.40569 4.13372 10 4.60917 10 5.25V9C10 9.55228 9.55228 10 9 10H2.00007C0.895534 10 0.000116153 9.10462 7.12099e-05 8.00008L6.51059e-05 7.85007Z"
                fill={
                    level === "Low" || level === "Medium"
                        ? "#F1BE00"
                        : level === "High"
                        ? "#FF3D3D"
                        : "#E6ECF1"
                }
            />
            <path
                d="M12 4.8198C12 4.34312 12.3365 3.93271 12.8039 3.83922L20.8039 2.23923C21.4227 2.11547 22 2.58877 22 3.21983L21.9999 9.00002C21.9999 9.5523 21.5521 10 20.9999 10H13C12.4477 10 12 9.55228 12 9V4.8198Z"
                fill={level === "Medium" ? "#F1BE00" : level === "High" ? "#FF3D3D" : "#E6ECF1"}
            />
            <path
                d="M24 2.8198C24 2.34312 24.3365 1.93271 24.8039 1.83922L31.6077 0.478454C32.8453 0.230935 34 1.17754 34 2.43965L33.9999 8.00003C33.9999 9.10459 33.1044 10 31.9999 10H25C24.4477 10 24 9.55229 24 9V2.8198Z"
                fill={level === "High" ? "#FF3D3D" : "#E6ECF1"}
            />
        </svg>
    );
};
