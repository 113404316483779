import { FC } from "react";
import styles from "./AnalystInfoCard.module.scss";

interface AnalystInfoCardProps {
    name: string;
    company: string;
    rating: number;
}

export const AnalystInfoCard: FC<AnalystInfoCardProps> = ({ name, company, rating }) => {
    return (
        <div className={styles.card}>
            <div className={styles.logo}>
                <img src="/images/analytics/analyst.png" alt="analyst icon" />
            </div>
            <div className={styles.info}>
                <span className={styles.name}>{name}</span>
                <span className={styles.company}>{company}</span>
                <div className={styles.rating}>
                    {rating > 0 &&
                        Array.from({ length: rating }).map((_, index) => (
                            <img key={index} src="/images/star_golden.svg" width={10} height={10}></img>
                        ))}
                </div>
                {/* <span>{rating}</span> */}
            </div>
        </div>
    );
};
