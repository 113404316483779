import { ErrorOption, FieldPath, FieldValues } from "react-hook-form";

const countDecimals = function (value) {
    if (Math.floor(value) !== value) {
        return value.toString().split(".")[1].length || 0;
    }

    return 0;
};

export const STEPS = [
    0.01,
    0.02,
    0.03,
    0.04,
    0.05,
    0.1,
    0.25,
    0.5,
    0.75,
    1,
    2,
    3,
    5,
    10,
    20,
    30,
    40,
    50,
    80,
    90,
    100,
];

export const getValueInRange = (value: number, minValue: number, maxValue: number): number => {
    let newValueInRange = value;

    if (value < minValue) {
        newValueInRange = minValue;
    } else if (value > maxValue) {
        newValueInRange = maxValue;
    }

    return newValueInRange;
};

let clearErrorsTimeoutHandle = null;

const VALIDATION_ERRORS_CLEAR_TIMEOUT_MS = 4000;

export const validateLotSizeValue = (
    value: string | number,
    clearErrors: () => void,
    setError: (
        name: FieldPath<FieldValues>,
        error: ErrorOption,
        options?: { shouldFocus: boolean }
    ) => void,
    min: number,
    max: number,
    t: (tk: string, options?: Record<string, unknown>) => string,
    setIsValid?: (value: boolean) => void
): boolean => {
    const clearErrorsAfterTimeout = () => {
        if (`${value}`.trim() === "") return;

        clearTimeout(clearErrorsTimeoutHandle);

        clearErrorsTimeoutHandle = setTimeout(() => {
            clearErrors();
            setIsValid && setIsValid(true);
        }, VALIDATION_ERRORS_CLEAR_TIMEOUT_MS);
    };

    if (["0", "0.", "0.0"].includes(`${value}`)) {
        clearErrors();

        return true;
    }

    const parsedValue = Number(value);

    if (Number.isNaN(parsedValue)) {
        setError("lotInput", {
            type: "custom",
            message: t("lotSize.error.onlyNumbers"),
        });

        return false;
    } else if (Number(value) < min) {
        setError("lotInput", {
            type: "custom",
            message: t("lotSize.error.range", { min, max }),
        });

        clearErrorsAfterTimeout();

        return false;
    } else if (Number(value) > max) {
        setError("lotInput", {
            type: "custom",
            message: t("lotSize.error.range", { min, max }),
        });

        clearErrorsAfterTimeout();

        return true;
    } else if (countDecimals(parsedValue) > 2) {
        setError("lotInput", {
            type: "custom",
            message: t("lotSize.error.decimalsMax", { decimalsMax: 2 }),
        });

        return false;
    }

    clearErrors();

    return true;
};
