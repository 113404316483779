import { CustomTable, TableTypes } from "@/components/Core/Table";
import { useState, useEffect } from "react";
import { IPivotPointsDto, technicalAnalysisApi } from "../../api";

const usePivotPoints = () => {
    const [data, setData] = useState<IPivotPointsDto[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await technicalAnalysisApi.getPivotPointsData();
            setData(data);
        };

        fetchData();
    }, []);

    return { data };
};

export const PivotPointsTable = () => {
    const { data } = usePivotPoints();

    return <CustomTable type={TableTypes.pivotPoints} data={data} />;
};
