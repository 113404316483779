import React from "react";
import clsx from "clsx";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import InfoList from "@/components/Core/InfoList/InfoList";
import { useInfoPageData } from "@/hooks/common/useInfoPageData";
import styles from "./TradingInfoMobile.module.scss";

const TradingInfoMobile = () => {
    const { t } = useTranslation("common");
    const { palette } = useTheme();
    const { symbolsInfoById, activeSymbolId } = useTradeInfo();
    const symbolInfo = symbolsInfoById[activeSymbolId];

    const { symbolData, tradingHoursData, pendingOrdersData } = useInfoPageData();

    return (
        <div
            className={clsx(styles.info, styles[`scrollArea_${palette.mode}`])}
            style={{ zIndex: 1601 }}>
            <InfoList title="" content={symbolData} className={styles.listInfoMobile} />

            <div className={styles.schedule}>
                <h1 className={styles.scheduleTitle}>{t("trading_hours")}</h1>
                <div className={styles.scheduleHourList}>
                    {tradingHoursData.map(({ label, value }, id) => (
                        <div key={`${label}-${id}`}>{`${label}: ${value}`}</div>
                    ))}
                </div>
            </div>

            {symbolInfo?.ex?.description && (
                <>
                    <InfoList
                        content={[
                            {
                                label: "",
                                value: symbolInfo?.ex?.description,
                            },
                        ]}
                        titleIcon="/images/description_grey.svg"
                        description
                        className={styles.listInfoMobile}
                    />
                    <div className={styles.emptyLine}></div>
                </>
            )}

            <InfoList title="" content={pendingOrdersData} glossary />
        </div>
    );
};

export default React.memo(TradingInfoMobile);
