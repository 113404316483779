import { FC } from "react";
import { ImpactIcon } from "../ImpactLevelIcon";
import { IEconomicCalendarDto } from "../../api";
import styles from "./EconomicCalendar.module.scss";

interface IEconomicCalendarDtoProps {
    data: IEconomicCalendarDto;
}

export const EconomicCalendarItem: FC<IEconomicCalendarDtoProps> = ({ data }) => {
    return (
        <div className={styles.item}>
            <div className={styles.label}>
                <div className={styles.title}>{data.title}</div>
                <div className={styles.timestamp}>{data.timestamp}</div>
            </div>
            <div className={styles.metrics}>
                <img
                    src={`/images/countries-flag/${data.country}.svg`}
                    height={24}
                    width={24}
                    className={styles.countryFlag}></img>
                <ImpactIcon level={data.impact} />
                <div className={styles.value}>{data.actual}</div>
                <div className={styles.value}>{data.estimate}</div>
                <div className={styles.value}>{data.previous}</div>
            </div>
        </div>
    );
};
