/* eslint-disable no-irregular-whitespace */
import { Card } from "./Card/Card";
import { MovingAveragesTable, PivotPointsTable, TechnicalIndicatorsTable } from "./tables";
import { GaugeChart } from "./charts/GaugeChart/GaugeChart";
import { TimeframesPanel, TimeFrames } from "./TimeframesPanel/TimeframesPanel";
import styles from "./index.module.scss";

export const TechnicalAnalysis = () => {
    return (
        <div className={styles.container}>
            <header className={styles.label}>Apple (AAPL) Technical Analysis</header>
            <main className={styles.content}>
                <Card title="Technical Sentiment">
                    <TimeframesPanel onChange={() => {}} selected={TimeFrames.M15} />
                    <div className={styles.charts}>
                        <GaugeChart />
                        <GaugeChart />
                        <GaugeChart />
                    </div>
                    <div className={styles.description}>
                        <span>
                            Apple’s (AAPL) Moving Averages Convergence Divergence (MACD) indicator
                            is 1.29, suggesting Apple is a Buy.
                        </span>
                        <span>
                            Apple’s (AAPL) 20-Day exponential moving average is 246.82, while Apple’s
                            (AAPL) share price is $242.7, making it a Sell.
                        </span>
                        <span>
                            Apple’s (AAPL) 50-Day exponential moving average is 241.15, while Apple’s
                            (AAPL) share price is $242.7, making it a Buy.
                        </span>
                    </div>
                </Card>
                <Card title="Apple (AAPL) Pivot Points">
                    <PivotPointsTable />
                </Card>
                <div className={styles.inline}>
                    <Card title="Apple (AAPL) Moving Averages">
                        <MovingAveragesTable />
                    </Card>
                    <Card title="Apple Inc (AAPL) Technical Indicators">
                        <TechnicalIndicatorsTable />
                    </Card>
                </div>
            </main>
        </div>
    );
};
