import { IEconomicCalendarDto } from "./index";

export const mockData: IEconomicCalendarDto[] = [
    {
        date: "2024-11-30T00:00:00Z",
        title: "Unemployment Rate",
        timestamp: "10:00 AM",
        actual: 4.1,
        estimate: 4.2,
        previous: 4.0,
        impact: "High",
        country: "us",
    },
    {
        date: "2024-11-29T00:00:00Z",
        title: "CPI",
        timestamp: "8:30 AM",
        actual: 7.0,
        estimate: 6.9,
        previous: 6.8,
        impact: "High",
        country: "us",
    },
    {
        date: "2024-11-28T00:00:00Z",
        title: "Personal Spending",
        timestamp: "8:30 AM",
        actual: 3.7,
        estimate: 3.6,
        previous: 3.5,
        impact: "Medium",
        country: "jp",
    },
    {
        date: "2024-11-27T00:00:00Z",
        title: "Personal Income",
        timestamp: "8:30 AM",
        actual: 4.0,
        estimate: 3.9,
        previous: 3.8,
        impact: "Medium",
        country: "jp",
    },
    {
        date: "2024-11-26T00:00:00Z",
        title: "Durable Goods Orders",
        timestamp: "8:30 AM",
        actual: 2.3,
        estimate: 2.2,
        previous: 2.1,
        impact: "Medium",
        country: "es",
    },
    {
        date: "2024-11-25T00:00:00Z",
        title: "Chicago PMI",
        timestamp: "9:45 AM",
        actual: 61.0,
        estimate: 60.5,
        previous: 60.0,
        impact: "Low",
        country: "es",
    },
    {
        date: "2024-11-24T00:00:00Z",
        title: "Core PCE Price Index",
        timestamp: "8:30 AM",
        actual: 1.6,
        estimate: 1.5,
        previous: 1.4,
        impact: "Medium",
        country: "de",
    },
    {
        date: "2024-11-23T00:00:00Z",
        title: "Consumer Confidence",
        timestamp: "10:00 AM",
        actual: 128.5,
        estimate: 128.0,
        previous: 127.5,
        impact: "Medium",
        country: "de",
    },
    {
        date: "2024-11-23T00:00:00Z",
        title: "Pending Home Sales",
        timestamp: "10:00 AM",
        actual: 0.8,
        estimate: 0.7,
        previous: 0.6,
        impact: "Low",
        country: "de",
    },
    {
        date: "2024-11-22T00:00:00Z",
        title: "New Home Sales",
        timestamp: "10:00 AM",
        actual: 675,
        estimate: 670,
        previous: 665,
        impact: "Medium",
        country: "au",
    },
    {
        date: "2024-11-21T00:00:00Z",
        title: "PCE Price Index",
        timestamp: "8:30 AM",
        actual: 2.2,
        estimate: 2.1,
        previous: 2.0,
        impact: "Medium",
        country: "au",
    },
    {
        date: "2024-11-20T00:00:00Z",
        title: "Personal Income",
        timestamp: "8:30 AM",
        actual: 4.0,
        estimate: 3.9,
        previous: 3.8,
        impact: "Medium",
        country: "au",
    },
    {
        date: "2024-11-20T00:00:00Z",
        title: "Personal Spending",
        timestamp: "8:30 AM",
        actual: 3.7,
        estimate: 3.6,
        previous: 3.5,
        impact: "Medium",
        country: "au",
    },
    {
        date: "2024-11-19T00:00:00Z",
        title: "GDP Growth Rate",
        timestamp: "8:30 AM",
        actual: 3.1,
        estimate: 3.0,
        previous: 2.9,
        impact: "High",
        country: "ez",
    },
    {
        date: "2024-11-18T00:00:00Z",
        title: "Jobless Claims",
        timestamp: "8:30 AM",
        actual: 210,
        estimate: 215,
        previous: 220,
        impact: "Low",
        country: "ez",
    },
    {
        date: "2024-11-18T00:00:00Z",
        title: "Durable Goods Orders",
        timestamp: "8:30 AM",
        actual: 2.3,
        estimate: 2.2,
        previous: 2.1,
        impact: "Medium",
        country: "ez",
    },
    {
        date: "2024-11-17T00:00:00Z",
        title: "Existing Home Sales",
        timestamp: "10:00 AM",
        actual: 5.45,
        estimate: 5.4,
        previous: 5.35,
        impact: "Medium",
        country: "sg",
    },
    {
        date: "2024-11-16T00:00:00Z",
        title: "Housing Starts",
        timestamp: "8:30 AM",
        actual: 1.25,
        estimate: 1.24,
        previous: 1.23,
        impact: "Medium",
        country: "sg",
    },
    {
        date: "2024-11-15T00:00:00Z",
        title: "Industrial Production",
        timestamp: "9:15 AM",
        actual: 4.3,
        estimate: 4.2,
        previous: 4.1,
        impact: "Medium",
        country: "sg",
    },
    {
        date: "2024-11-15T00:00:00Z",
        title: "Consumer Sentiment",
        timestamp: "10:00 AM",
        actual: 96.5,
        estimate: 96.2,
        previous: 96.0,
        impact: "Medium",
        country: "fr",
    },
    {
        date: "2024-11-14T00:00:00Z",
        title: "Retail Sales",
        timestamp: "8:30 AM",
        actual: 5.2,
        estimate: 5.1,
        previous: 5.0,
        impact: "High",
        country: "hk",
    },
    {
        date: "2024-11-13T00:00:00Z",
        title: "PPI",
        timestamp: "8:30 AM",
        actual: 3.5,
        estimate: 3.4,
        previous: 3.3,
        impact: "Medium",
        country: "us",
    },
    {
        date: "2024-11-12T00:00:00Z",
        title: "CPI",
        timestamp: "8:30 AM",
        actual: 7.0,
        estimate: 6.9,
        previous: 6.8,
        impact: "High",
        country: "hk",
    },
    {
        date: "2024-11-12T00:00:00Z",
        title: "Unemployment Rate",
        timestamp: "10:00 AM",
        actual: 4.1,
        estimate: 4.2,
        previous: 4.0,
        impact: "High",
        country: "us",
    },
    {
        date: "2024-11-11T00:00:00Z",
        title: "NFIB Business Optimism Index",
        timestamp: "1:00 PM",
        actual: 93.7,
        estimate: 91.9,
        previous: 91.5,
        impact: "Medium",
        country: "fr",
    },
    {
        date: "2024-11-11T00:00:00Z",
        title: "Consumer Confidence Report",
        timestamp: "2:00 PM",
        actual: 105.4,
        estimate: 104.0,
        previous: 103.2,
        impact: "Medium",
        country: "fr",
    },
];
