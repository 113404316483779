/* eslint-disable react/display-name */
import clsx from "clsx";
import moment from "moment";
import styles from "./Table.module.scss";
import { AnalystInfoCard } from "./parts/AnalystInfoCard";

export enum TableTypes {
    pivotPoints = "pivotPoints",
    movingAverages = "movingAverages",
    technicalIndicators = "technicalIndicators",
    analystForecasts = "analystForecasts",
}

export interface ITableHeader {
    accessor: string;
    label: string;
    align?: "left" | "right" | "center";
    renderValue?: (value) => string | JSX.Element;
}

export type ITableConfig = Record<TableTypes, { columns: ITableHeader[]; title: string }>;

export const tablesConfig: ITableConfig = {
    [TableTypes.pivotPoints]: {
        title: "Apple (AAPL) Pivot Points",
        columns: [
            { accessor: "name", label: "name", renderValue: rowData => rowData["name"] },
            { accessor: "s3", label: "s3", align: "center", renderValue: rowData => rowData["s3"] },
            { accessor: "s2", label: "s2", align: "center", renderValue: rowData => rowData["s2"] },
            { accessor: "s1", label: "s1", align: "center", renderValue: rowData => rowData["s1"] },
            {
                accessor: "pivotPoints",
                label: "pivot points",
                align: "center",
                renderValue: rowData => rowData["pivotPoints"],
            },
            { accessor: "r1", label: "r1", align: "center", renderValue: rowData => rowData["r1"] },
            { accessor: "r2", label: "r2", align: "center", renderValue: rowData => rowData["r2"] },
            { accessor: "r3", label: "r3", align: "right", renderValue: rowData => rowData["r3"] },
        ],
    },
    [TableTypes.movingAverages]: {
        title: "Apple (AAPL) Moving Averages",
        columns: [
            { accessor: "period", label: "Period", renderValue: rowData => rowData["period"] },
            {
                accessor: "simple",
                label: "Simple",
                align: "center",
                renderValue: rowData => (
                    <div className={clsx(styles.tableCellValue, styles.flexColumn)}>
                        {rowData["simple"]}
                        <div
                            className={clsx(null, {
                                [styles.buy]: rowData["action"] === "Buy",
                                [styles.sell]: rowData["action"] === "Sell",
                            })}>
                            {rowData["action"]}
                        </div>
                    </div>
                ),
            },
            {
                accessor: "exponential",
                label: "Exponential",
                align: "right",
                renderValue: rowData => (
                    <div className={clsx(styles.tableCellValue, styles.flexColumn)}>
                        {rowData["simple"]}
                        <div
                            className={clsx(null, {
                                [styles.buy]: rowData["action"] === "Buy",
                                [styles.sell]: rowData["action"] === "Sell",
                            })}>
                            {rowData["action"]}
                        </div>
                    </div>
                ),
            },
        ],
    },
    [TableTypes.technicalIndicators]: {
        title: "Apple Inc (AAPL) Technical Indicators",
        columns: [
            { accessor: "name", label: "name", renderValue: rowData => rowData["name"] },
            {
                accessor: "value",
                label: "Value",
                align: "center",
                renderValue: rowData => rowData["value"],
            },
            {
                accessor: "action",
                label: "Implied Action",
                align: "right",
                renderValue: rowData => (
                    <div
                        className={clsx(styles.tableCellValue, {
                            [styles.buy]: rowData["action"] === "Buy",
                            [styles.sell]: rowData["action"] === "Sell",
                        })}>
                        {rowData["action"]}
                    </div>
                ),
            },
        ],
    },
    [TableTypes.analystForecasts]: {
        title: "AAPL Analyst Forecasts",
        columns: [
            {
                accessor: "analystName",
                label: "Analyst Name",
                renderValue: rowData => {
                    const { name, rating, company } = rowData["analystInfo"];
                    return <AnalystInfoCard name={name} company={company} rating={rating} />;
                },
            },
            {
                accessor: "position",
                label: "Position",
                align: "center",
                renderValue: rowData => (
                    <div
                        className={clsx(styles.tableCellValue, {
                            [styles.buy]: rowData["position"] === "Buy",
                            [styles.sell]: rowData["position"] === "Sell",
                        })}>
                        {rowData["position"]}
                    </div>
                ),
            },
            {
                accessor: "priceTarget",
                label: "Price Target",
                align: "center",
                renderValue: rowData => (
                    <div
                        className={clsx(styles.tableCellValue, {
                            [styles.buy]: rowData["position"] === "Buy",
                            [styles.sell]: rowData["position"] === "Sell",
                        })}>
                        {rowData["priceTarget"]}
                    </div>
                ),
            },
            {
                accessor: "action",
                label: "Action",
                align: "center",
                renderValue: rowData => rowData["action"],
            },
            {
                accessor: "date",
                label: "Date",
                align: "right",
                renderValue: rowData => moment(rowData["date"]).format("DD.MM.YYYY"),
            },
        ],
    },
};
