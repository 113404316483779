import { AnalystForecastsTable } from "./AnalystForecastsTable";
// import AnalystRatingsChart from "./charts/AnalystRatings/AnalystRatings";
// import { MonthForecastChart } from "./charts/MonthForecast/MonthForecast";
import styles from "./index.module.scss";

export const AnalystForecasts = () => {
    return (
        <div className={styles.container}>
            <h1>Analyst Forecasts Content</h1>
            {/* <div className={styles.charts}>
                <AnalystRatingsChart />
                <MonthForecastChart />
            </div> */}
            <div className={styles.table}>
                <AnalystForecastsTable />
            </div>
        </div>
    );
};
