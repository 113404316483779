import React from "react";
import clsx from "clsx";
import { useTheme } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { useUI } from "@/redux/selectors/uiSelector";
import InfoList from "@/components/Core/InfoList/InfoList";
import { getSymbolNameTranslation } from "@/utils/symbols";
import { useInfoPageData } from "@/hooks/common/useInfoPageData";
import styles from "../TradingChartInfoSection.module.scss";

const TradingInfo = () => {
    const { palette } = useTheme();
    const { t } = useTranslation();
    const { isTradeOrderViewOpen, isAssetsTableExpanded } = useUI();
    const isNarrow = isTradeOrderViewOpen && isAssetsTableExpanded;
    const { symbolsInfoById, activeSymbolId } = useTradeInfo();
    const symbolInfo = symbolsInfoById[activeSymbolId];

    const { symbolData, tradingHoursData, pendingOrdersData } = useInfoPageData();

    return (
        <section
            className={clsx(styles.grid_section, {
                [styles.singleColumn]: isNarrow,
            })}>
            <div className={styles.grid_item}>
                <InfoList title="information" content={symbolData} decreased />
            </div>
            <div className={styles.grid_item}>
                <InfoList
                    title="trading_hours"
                    content={tradingHoursData}
                    titleIcon={`/images/clock_bold${palette.mode === "dark" ? "" : "_light"}.svg`}
                    decreased
                    trading_hours
                />
                {symbolInfo?.ex?.description && screen.width >= 1750 && (
                    <InfoList
                        title="description"
                        content={[
                            {
                                label: "",
                                value:
                                    symbolInfo.group.name === "Currencies"
                                        ? getSymbolNameTranslation(symbolInfo, t)
                                        : symbolInfo.ex.description,
                            },
                        ]}
                        titleIcon={`/images/description_${
                            palette.mode === "dark" ? "" : "dark"
                        }grey.svg`}
                        description
                    />
                )}

                <div className={clsx(styles.grid_item, styles.showOnSmallScreen)}>
                    <InfoList title="pendingTrades" content={pendingOrdersData} glossary />
                </div>
            </div>
            {symbolInfo?.ex?.description && isNarrow && screen.width < 1750 && (
                <div className={styles.grid_item}>
                    <InfoList
                        title="description"
                        content={[
                            {
                                label: "",
                                value:
                                    symbolInfo.group.name === "Currencies"
                                        ? getSymbolNameTranslation(symbolInfo, t)
                                        : symbolInfo.ex.description,
                            },
                        ]}
                        titleIcon={`/images/description_${
                            palette.mode === "dark" ? "" : "dark"
                        }grey.svg`}
                        description
                    />
                </div>
            )}
            <div className={clsx(styles.grid_item, styles.hideOnSmallScreen)}>
                <InfoList title="pendingTrades" content={pendingOrdersData} glossary />
            </div>
        </section>
    );
};

export default React.memo(TradingInfo);
