import { CustomTable, TableTypes } from "@/components/Core/Table";
import { useState, useEffect } from "react";
import { ITechnicalIndicatorsDto, technicalAnalysisApi } from "../../api";

const useTechnicalIndicators = () => {
    const [data, setData] = useState<ITechnicalIndicatorsDto[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await technicalAnalysisApi.getTechnicalIndicatorsData();
            setData(data);
        };

        fetchData();
    }, []);

    return { data };
};

export const TechnicalIndicatorsTable = () => {
    const { data } = useTechnicalIndicators();

    return <CustomTable type={TableTypes.technicalIndicators} data={data} />;
};
