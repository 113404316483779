import { CustomTable, TableTypes } from "@/components/Core/Table";
import { useEffect, useState } from "react";
import { analystForecastsApi, IAnalystForecastsDto } from "../api";

const useAnalystForecasts = () => {
    const [data, setData] = useState<IAnalystForecastsDto[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await analystForecastsApi.getData();
            setData(data);
        };

        fetchData();
    }, []);

    return { data };
};

export const AnalystForecastsTable = () => {
    const { data } = useAnalystForecasts();

    return <CustomTable type={TableTypes.analystForecasts} data={data} />;
};
