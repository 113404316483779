import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import styles from "./GaugeChart.module.scss";

export const GaugeChart = () => {
    const data = [
        { value: 30, color: "#D32F2F" }, // Strong Sell
        { value: 30, color: "#FFAB00" }, // Neutral
        { value: 40, color: "#00C853" }, // Strong Buy
    ];

    const needleValue = 70; // Example value between 0-100
    const needleRadius = 10;

    // Calculate needle position
    const RADIAN = Math.PI / 180;
    const cx = 200; // Chart center x
    const cy = 200; // Chart center y
    const startAngle = 180; // Gauge start angle
    const endAngle = 0; // Gauge end angle
    const angle = startAngle + (endAngle - startAngle) * (needleValue / 100);
    const needleX = cx + Math.cos(-angle * RADIAN) * 100; // Adjust radius as needed
    const needleY = cy + Math.sin(-angle * RADIAN) * 100;

    return (
        <div className={styles.container}>
            <h3 style={{ textAlign: "center", color: "#ffffff" }}>Overall Consensus</h3>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                    {/* Gauge background */}
                    <Pie
                        data={data}
                        startAngle={180}
                        endAngle={0}
                        innerRadius="70%"
                        outerRadius="100%"
                        dataKey="value">
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Pie>
                    {/* Center Needle */}
                    <svg>
                        {/* Needle line */}
                        <line
                            x1={cx}
                            y1={cy}
                            x2={needleX}
                            y2={needleY}
                            stroke="#ffffff"
                            strokeWidth={2}
                        />
                        {/* Needle center */}
                        <circle cx={cx} cy={cy} r={needleRadius} fill="#ffffff" />
                    </svg>
                </PieChart>
            </ResponsiveContainer>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "#ffffff",
                    marginTop: -40,
                }}>
                <span>Strong Sell</span>
                <span>Buy</span>
                <span>Strong Buy</span>
            </div>
        </div>
    );
};
