import React, { useContext, useRef, useState } from "react";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from "next-i18next";

import { useUI } from "@/redux/selectors/uiSelector";
import { useIntroStep } from "@/redux/selectors/modalSelector";
import TradeOrderModal from "./Modal";
import TradingOrderForm, { DynamicFormHeader } from "./TradingOrderForm";
import { TradeOrderDispatchContext, TradeOrderStateContext } from "@/contexts/TradeOrder";
import { togglePendingOrder } from "@/contexts/TradeOrder/actions";
// import { ModalTestingComponent } from "../../../utils/ModalsTestComponent";

import styles from "./TradingOrderSection.module.scss";

const TradingOrderSection: React.FC = () => {
    const { t } = useTranslation("common");
    const wrapperRef = useRef<HTMLDivElement>(null);

    const { tradeOrderModal } = useUI();

    const [isAdvancedTrade, setIsAdvancedTrade] = useState(false);
    const { isPendingOrder } = useContext(TradeOrderStateContext);
    const tradeOrderDispatch = useContext(TradeOrderDispatchContext);
    const introStep = useIntroStep();

    return (
        <>
            {/* <ModalTestingComponent /> */}
            <div
                id="trade_order_section"
                className={clsx(styles.container, {
                    [styles.overlayed]: introStep === 3,
                    [styles.isModalOpen]: Boolean(tradeOrderModal),
                })}
                ref={wrapperRef}>
                <TradeOrderModal />

                <div className={styles.tabs}>
                    {[false, true].map((tabValue, index) => {
                        const isActive = tabValue === isAdvancedTrade;

                        return (
                            <div
                                key={index}
                                className={clsx(styles.tab, { [styles.tabActive]: isActive })}
                                onClick={() => {
                                    if (isPendingOrder && isAdvancedTrade) {
                                        tradeOrderDispatch(togglePendingOrder());
                                    }
                                    setIsAdvancedTrade(tabValue);
                                }}>
                                {tabValue ? t("advanced_trades") : t("market_execution")}
                            </div>
                        );
                    })}
                </div>
                <DynamicFormHeader />

                <PerfectScrollbar>
                    <div id="trade_order_section_content" className={styles.content}>
                        <TradingOrderForm
                            tradeBtnWidth={wrapperRef.current?.clientWidth}
                            isAdvanced={isAdvancedTrade}
                        />
                    </div>
                </PerfectScrollbar>
            </div>
        </>
    );
};

export default TradingOrderSection;
