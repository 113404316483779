import { formatAmount } from "./format";
export const getMarginLevelDisplayValue = ({
    margin,
    isAnyOpenTrades,
    marginLevel,
}: {
    margin: number;
    isAnyOpenTrades: boolean;
    marginLevel: number;
}): string => {
    if (!isAnyOpenTrades) return "---";
    if (margin === 0) return `${margin}%`;
    return `${marginLevel.toFixed(2)}%`;
};

export const getUsedMarginDisplayValue = ({
    margin,
    isAnyOpenTrades,
    accountCurrency,
}: {
    margin: number;
    isAnyOpenTrades: boolean;
    accountCurrency: string;
}): string => {
    if (!isAnyOpenTrades) return "---";
    if (margin === 0) return `${margin}`;
    return formatAmount(margin, accountCurrency);
};
