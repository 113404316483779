import { CustomTable, TableTypes } from "@/components/Core/Table";
import { useState, useEffect } from "react";
import { IMovingAveragessDto, technicalAnalysisApi } from "../../api";

const useMovingAverages = () => {
    const [data, setData] = useState<IMovingAveragessDto[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await technicalAnalysisApi.getMovingAveragesData();
            setData(data);
        };

        fetchData();
    }, []);

    return { data };
};

export const MovingAveragesTable = () => {
    const { data } = useMovingAverages();

    return <CustomTable type={TableTypes.movingAverages} data={data} />;
};
