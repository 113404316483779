import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import "react-modern-drawer/dist/index.css";
import Cookies from "js-cookie";

import {
    setIsAssetsTableExpanded,
    setIsOpenTradesOpen,
    setIsPendingOrdersOpen,
    setIsTradingHistoryOpen,
    toggleNotificationsPopup,
} from "@/redux/actions/ui";
import { setTradeAlerts } from "@/redux/actions/account";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";
import { PLATFORM_INTRO_COOKIE_KEY } from "@/constants/index";

import { Controls } from "./components/Controls";
import { Calculate } from "../calculate/Calculate";
import { IntroModal } from "../Intro";

import { openModal } from "@/redux/actions/modal";
import { DispatchOpenModal } from "@/utils/modals";
import { useUI } from "@/redux/selectors/uiSelector";
import { useIntroStep } from "@/redux/selectors/modalSelector";
import { fetchAlertsDataFromFirebase } from "@/services/firebase";
import { useAccountInfo } from "@/redux/selectors/accountSelector";
import { setIsTradeAlertsLoading } from "@/redux/actions/ui";
import { filterOutHidden } from "@/services/firebase/helpers";
import { EconomicCalendar } from "../EconomicCalendar/ui/EcomomicCalendar/EconomicCelendar";
import styles from "./footer.module.scss";

interface IProps {
    handleToggleFooter: (value: boolean) => void;
}
export const openIntroModal = (category, dispatch: DispatchOpenModal): void =>
    dispatch(openModal({ category }));

const FooterToolbar: React.FC<IProps> = () => {
    const dispatch = useDispatch();
    const { isQuoteDataLoaded, symbolsInfoById } = useTradeInfo();
    const { isAssetsTableExpanded, isNotificationsPopupVisible } = useUI();
    const { tradeAlerts, tradeAlertsLastRequestTime } = useAccountInfo();
    const { isGuest, isLazyMode } = useAccountInfo();
    const [showIntro, setShowIntro] = useState(false);
    const [showCalculator, showCalculatorToggle] = useState(false);
    const [showEconomicCalendar, setShowEconomicCalendar] = useState(false);
    // store assets list expanded state for intro
    const isAssetsTableExpandedRef = useRef(false);
    const introStep = useIntroStep();
    const toggleExpand = () => dispatch(setIsAssetsTableExpanded(!isAssetsTableExpanded));

    const closeIntro = () => {
        if (isAssetsTableExpandedRef.current) {
            toggleExpand();
        }
        setShowIntro(false);
    };

    const handleShowEconomicCalendar = () => {
        setShowEconomicCalendar(prev => !prev);
    };

    const handleToggleCalculator = value => {
        showCalculatorToggle(value);
        setShowIntro(false);
        dispatch(setIsOpenTradesOpen(false));
        dispatch(setIsPendingOrdersOpen(false));
    };

    const handleToggleIntro = () => {
        if (!showIntro) {
            isAssetsTableExpandedRef.current = isAssetsTableExpanded;
            if (isAssetsTableExpanded) {
                toggleExpand();
            }
        }

        setShowIntro(!showIntro);
        showCalculatorToggle(false);

        dispatch(setIsOpenTradesOpen(false));
        dispatch(setIsPendingOrdersOpen(false));
    };

    const closeTradeTables = useCallback(() => {
        dispatch(setIsOpenTradesOpen(false));
        dispatch(setIsPendingOrdersOpen(false));
        dispatch(setIsTradingHistoryOpen(false));
    }, [dispatch]);

    const openTradesToggle = value => {
        dispatch(setIsOpenTradesOpen(value));
        showCalculatorToggle(false);
        setShowIntro(false);
    };

    const tradingHistoryToggle = value => {
        showCalculatorToggle(false);
        dispatch(setIsTradingHistoryOpen(value));
        setShowIntro(false);
    };
    const pendingOrdersToggle = value => {
        dispatch(setIsPendingOrdersOpen(value));
        setShowIntro(false);
        showCalculatorToggle(false);
    };

    const getAlerts = async () => {
        if (isGuest && !isLazyMode) return;
        const timestamp = Date.now();
        const difference = timestamp - tradeAlertsLastRequestTime;
        const delay = 10 * 60 * 1000;

        if (!tradeAlertsLastRequestTime || difference >= delay) {
            dispatch(setIsTradeAlertsLoading(true));
            const alerts = await fetchAlertsDataFromFirebase(symbolsInfoById);
            dispatch(setTradeAlerts(alerts, timestamp));
            dispatch(setIsTradeAlertsLoading(false));
        } else {
            dispatch(
                setTradeAlerts(filterOutHidden(tradeAlerts, symbolsInfoById), tradeAlertsLastRequestTime)
            );
        }
    };

    const handleToggleNotifications = () => {
        !isNotificationsPopupVisible && getAlerts();
        dispatch(toggleNotificationsPopup(!isNotificationsPopupVisible));
        setShowIntro(false);
        showCalculatorToggle(false);
        dispatch(setIsOpenTradesOpen(false));
        dispatch(setIsPendingOrdersOpen(false));
        dispatch(setIsTradingHistoryOpen(false));
    };

    useEffect(() => {
        const stepsToClose = [1, 2, 3, 4, 6, 7];
        const shouldClose = stepsToClose.some(n => {
            return n === introStep;
        });

        if (introStep === 5) {
            dispatch(setIsPendingOrdersOpen(false));
            dispatch(setIsOpenTradesOpen(true));
        } else if (shouldClose) {
            closeTradeTables();
        }
    }, [dispatch, introStep, closeTradeTables]);

    useEffect(() => {
        if (isQuoteDataLoaded && !Cookies.get(PLATFORM_INTRO_COOKIE_KEY)) {
            setShowIntro(true);
            showCalculatorToggle(false);
        }
    }, [isQuoteDataLoaded]);

    return (
        <div className={clsx(styles.footer_right_wrapper)}>
            {showIntro && <IntroModal isOpen={showIntro} closeModal={closeIntro} />}
            {showEconomicCalendar && <EconomicCalendar setOpen={setShowEconomicCalendar} />}
            <Calculate open={showCalculator} setOpen={showCalculatorToggle} />

            <Controls
                showIntro={showIntro}
                setShowIntro={handleToggleIntro}
                showCalculator={showCalculator}
                showEconomicCalendar={showEconomicCalendar}
                showNotifications={isNotificationsPopupVisible}
                handleToggleNotifications={handleToggleNotifications}
                handleToggleCalculator={handleToggleCalculator}
                handleShowEconomicCalendar={handleShowEconomicCalendar}
                openTradesToggle={openTradesToggle}
                tradingHistoryToggle={tradingHistoryToggle}
                pendingOrdersToggle={pendingOrdersToggle}
            />
        </div>
    );
};

export default FooterToolbar;
