import { TableTypes } from "@/components/Core/Table";

export const mockData = {
    [TableTypes.pivotPoints]: [
        ...["Classic", "Fibonacci", "Classic", "Fibonacci", "Classic", "Fibonacci"].map(name => ({
            name,
            s3: 232.91,
            s2: 232.91,
            s1: 232.91,
            pivotPoints: 232.91,
            r1: 232.91,
            r2: 232.91,
            r3: 232.91,
        })),
    ],
    [TableTypes.movingAverages]: [
        { period: "MA5", simple: 243.42, exponential: 244.46, action: "Sell" },
        { period: "MA10", simple: 249.25, exponential: 246.72, action: "Buy" },
        { period: "MA20", simple: 249.75, exponential: 246.82, action: "Sell" },
        { period: "MA50", simple: 238.6, exponential: 241.15, action: "Buy" },
        { period: "MA100", simple: 232.58, exponential: 232.91, action: "Sell" },
        { period: "MA200", simple: 214.8, exponential: 216.05, action: "Buy" },
    ],
    [TableTypes.technicalIndicators]: [
        ...Array.from({ length: 10 }).map((_, index) => ({
            name: "RSI (14)",
            value: 44.93,
            action: index % 2 === 0 ? "Buy" : "Sell",
        })),
    ],
};
