import { TableTypes } from "@/components/Core/Table";

export const mockData = {
    [TableTypes.analystForecasts]: [
        ...Array.from({ length: 10 }).map((_, index) => ({
            analystInfo: {
                name: "Amit Daryanani",
                company: "Evercore ISI",
                rating: 5,
            },
            position: index % 2 === 0 ? "Buy" : "Sell",
            priceTarget: "123$",
            // currency: "USD",
            action: "Reiterated",
            date: "2025-07-01T00:00:00Z",
        })),
    ],
};
