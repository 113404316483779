import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

import { useAccountDynamicInfo, useAccountInfo } from "@/redux/selectors/accountSelector";
import { WalletPages } from "../../WalletSectionMobile";
import { setToggleMobileFooter } from "@/redux/actions/ui";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { DevicePlatform } from "@/redux/interfaces/IDevice";
import {
    Balance,
    Header,
    TradeInfo,
} from "@/components/Home/TradingAssetSectionMobile/components/AccountInfo";
import { useTouchState, PressedButtons } from "@/hooks/common/useTouchState";

import styles from "../../WalletSectionMobile.module.scss";

interface IWalletSection {
    openCard: (cardType: WalletPages) => () => void;
}

const WalletSection: React.FC<IWalletSection> = ({ openCard }) => {
    const { palette } = useTheme();
    const { t } = useTranslation("common");
    const { locale } = useRouter();
    const dispatch = useDispatch();
    const [displayInfo, setDisplayInfo] = useState(false);
    const { platform } = useDevice();
    const isTablet = platform === DevicePlatform.Tablet;
    const { currency: accountCurrency } = useAccountInfo();

    const {
        balance = 0,
        equity = 0,
        credit = 0,
        margin = 0,
        freeMargin = 0,
        profit = 0,
    } = useAccountDynamicInfo();
    const { isTouched, buttonName, onTouchStart, onTouchEnd } = useTouchState();

    const marginLevel = (equity / margin) * 100;

    useEffect(() => {
        dispatch(setToggleMobileFooter(!displayInfo));
    }, [dispatch, displayInfo]);

    return (
        <div className={clsx(styles.container, { [styles.balanceInfoOpen]: displayInfo })}>
            {displayInfo && (
                <div
                    className={styles[`closeIcon_${palette.mode}`]}
                    onClick={() => setDisplayInfo(false)}
                />
            )}
            <div
                className={clsx(styles.mainInfoSection, {
                    [styles.tablet]: isTablet,
                })}>
                {displayInfo && <Header />}
                <Balance currency={accountCurrency} value={balance} />
            </div>
            <div
                className={clsx(styles.footerInfoSection, {
                    [styles.footerInfoSection_expanded]: displayInfo,
                    [styles.tablet]: isTablet && !displayInfo,
                })}>
                {displayInfo ? (
                    <TradeInfo
                        profit={profit}
                        credit={credit}
                        freeMargin={freeMargin}
                        margin={margin}
                        equity={equity}
                        marginLevel={marginLevel}
                        accountCurrency={accountCurrency}
                    />
                ) : (
                    <>
                        <div className={styles.infoIcon} onClick={() => setDisplayInfo(true)} />
                        <div
                            className={clsx(styles.cards, {
                                [styles.tablet]: isTablet,
                            })}>
                            <div
                                className={clsx(styles.card, {
                                    [styles.tablet]: isTablet,
                                    [styles.touched]: isTouched && buttonName === PressedButtons.DEPOSIT,
                                })}
                                onClick={openCard(WalletPages.DEPOSIT)}
                                onTouchStart={() => onTouchStart(PressedButtons.DEPOSIT)}
                                onTouchEnd={onTouchEnd}>
                                <div className={styles.depositIcon} />
                                <div
                                    className={clsx(styles.btnTitle, {
                                        [styles.longText]: locale === "ru",
                                    })}>
                                    {t("deposit")}
                                </div>
                            </div>
                            <div
                                className={clsx(styles.card, {
                                    [styles.tablet]: isTablet,
                                    [styles.touched]:
                                        isTouched && buttonName === PressedButtons.WITHDRAWAL,
                                })}
                                onClick={openCard(WalletPages.WITHDRAWAL)}
                                onTouchStart={() => onTouchStart(PressedButtons.WITHDRAWAL)}
                                onTouchEnd={onTouchEnd}>
                                <div className={styles.withdrawalIcon} />
                                <div
                                    className={clsx(styles.btnTitle, {
                                        [styles.longText]: locale === "ru",
                                    })}>
                                    {t("withdrawal")}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default React.memo(WalletSection);
