import * as React from "react";
import { FC } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ITableHeader, tablesConfig, TableTypes } from "./tablesConfig";
import styles from "./Table.module.scss";

interface ITableProps<T = unknown> {
    type: TableTypes;
    data: T[];
}

const TableValueRenderer = ({
    rowData,
    columnConfig,
}: {
    rowData: unknown;
    columnConfig: ITableHeader;
}) => {
    return columnConfig.renderValue(rowData);
};

const getTableHeaders = (headers: ITableHeader[]) => {
    return (
        <TableHead className={styles.tableHead}>
            <TableRow>
                {headers.map((header, index) => (
                    <TableCell key={index} align={header.align || "left"}>
                        {header.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const getTableBody = (rows: unknown[], columnsConfig: ITableHeader[]) => {
    return (
        <TableBody className={styles.tableBody}>
            {rows.map((row, rowIndex) => (
                <TableRow
                    className={styles.tableRow}
                    key={rowIndex}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    {columnsConfig.map((columnConfig, cellIndex) => (
                        <TableCell
                            className={styles.tableCell}
                            align={columnConfig.align || "left"}
                            key={cellIndex}>
                            <TableValueRenderer rowData={row} columnConfig={columnConfig} />
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </TableBody>
    );
};

export const CustomTable: FC<ITableProps> = ({ type, data }) => {
    const config = tablesConfig[type];
    return (
        <TableContainer component={Paper} className={styles.tableContainer}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label={`${config.title} || 'table'`}>
                {getTableHeaders(config.columns)}
                {getTableBody(data, config.columns)}
            </Table>
        </TableContainer>
    );
};
