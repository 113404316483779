import { useState, useEffect, useMemo } from "react";
import { IEconomicCalendarDto, economicCalendarApi } from "./api";
import { CountryCodes } from "./ui/CountrySelect";
import { ImpactLevels } from "./ui/ImpactLevelSelect";

const filterData = (
    data: IEconomicCalendarDto[],
    selectedImpactLevel: ImpactLevels,
    selectedCountry: CountryCodes
): IEconomicCalendarDto[] => {
    return data.filter(
        item =>
            item.country === selectedCountry &&
            (item.impact === selectedImpactLevel || selectedImpactLevel === ImpactLevels.All)
    );
};

const groupDataByDate = (data: IEconomicCalendarDto[]) => {
    return data.reduce((acc, item) => {
        const date = item.date;
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(item);
        return acc;
    }, {} as Record<string, IEconomicCalendarDto[]>);
};

export const useEconomicCalendar = () => {
    const [data, setData] = useState<IEconomicCalendarDto[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [selectedImpactLevel, setImpactLevel] = useState<ImpactLevels>(ImpactLevels.All);
    const [selectedCountry, setCountry] = useState<CountryCodes>(CountryCodes.USA);

    const fetchData = async () => {
        try {
            setLoading(true);
            const data = await economicCalendarApi.getData();
            setData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // filter data by impact level and country
    const filteredData = useMemo(() => {
        return filterData(data, selectedImpactLevel, selectedCountry);
    }, [data, selectedImpactLevel, selectedCountry]);

    // group data by date
    const groupedData = useMemo(() => {
        const sortedData = [...filteredData].sort((a, b) => +new Date(b.date) - +new Date(a.date));
        return groupDataByDate(sortedData);
    }, [filteredData]);

    return {
        data: groupedData,
        isLoading,
        selectedImpactLevel,
        selectedCountry,
        setImpactLevel,
        setCountry,
    };
};
