// import { callApi } from "@/services/index";
import { TableTypes } from "@/components/Core/Table";
import { mockData } from "./mockData";

export interface IAnalystForecastsDto {
    analystInfo: {
        name: string;
        company: string;
        rating: number;
    };
    position: string;
    priceTarget: string;
    action: string;
    date: string;
}

export const analystForecastsApi = {
    getData: async (): Promise<IAnalystForecastsDto[]> => {
        return Promise.resolve(mockData[TableTypes.analystForecasts]);

        //     const response = await callApi({
        //         endpoint: "/api/analyst-forecasts",
        //         method: "GET",
        //     });

        //     if (response?.isFailed) {
        //         return [];
        //     }

        //     return response as Promise<IEconomicCalendarDto[]>;
    },

    // ...
};
