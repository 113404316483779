import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "next-i18next";
import clsx from "clsx";
import {
    getMarginLevelDisplayValue,
    getUsedMarginDisplayValue,
} from "@/utils/getMarginLevelDisplayValue";
import { ThemeSwitcher } from "./components/ThemeSwitcher";
import { setToggleExpandFooter } from "@/redux/actions/ui";
import { useAccountInfo, useAccountDynamicInfo } from "@/redux/selectors/accountSelector";
import { formatAmount } from "@/utils/format";
import { TradeHistory } from "@/components/TradeHistory/TradeHistory";
import { useUI } from "@/redux/selectors/uiSelector";
import { useIntroStep } from "@/redux/selectors/modalSelector";
import FooterToolbar from "./FooterToolbar";
import { useLoginInfo } from "@/hooks/common/useLoginInfo";
import { isEURegulation } from "@/utils/trade";
import { useTradeOrders } from "@/redux/selectors/ordersSelector";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";

import styles from "./footer.module.scss";

export enum OrderTableType {
    OPEN_TRADES = "open",
    PENDING_ORDERS = "pending",
    CLOSED = "closed",
}

const emptyValue = "---";

const UserInfo = (): JSX.Element => {
    const { t } = useTranslation("common");
    const { isLoggedInUser } = useLoginInfo();
    const { currency: accountCurrency } = useAccountInfo();
    const {
        balance = 0,
        credit = 0,
        equity = 0,
        margin = 0,
        freeMargin = 0,
        profit = 0,
        isSetDynamicAccountInfoCalculated,
    } = useAccountDynamicInfo();
    const { openTrades } = useTradeOrders();
    const marginLevel = (equity / margin) * 100;
    const isEU = useMemo(() => isEURegulation(), []);

    let marginLevelDisplayValue = emptyValue;
    let totalPLDisplayValue = emptyValue;
    let freeMarginDisplayValue = emptyValue;
    let equityDisplayValue = emptyValue;
    let balanceDisplayValue = emptyValue;
    let creditDisplayValue = emptyValue;

    if (isLoggedInUser && isSetDynamicAccountInfoCalculated) {
        marginLevelDisplayValue = getMarginLevelDisplayValue({
            margin,
            marginLevel,
            isAnyOpenTrades: openTrades && openTrades.length > 0,
        });
        totalPLDisplayValue = formatAmount(profit, accountCurrency);
        freeMarginDisplayValue = formatAmount(freeMargin, accountCurrency);
        equityDisplayValue = formatAmount(equity, accountCurrency);
        balanceDisplayValue = formatAmount(balance, accountCurrency);
        creditDisplayValue = formatAmount(credit, accountCurrency);
    }

    return (
        <div className={styles.userInfo}>
            <div className={styles.userInfoItem}>
                <span className={styles.userInfoLabel}>{t("balance")}</span>
                <span className={styles.userInfoValue}>{balanceDisplayValue}</span>
            </div>
            {!isEU || credit > 0 ? (
                <div className={styles.userInfoItem}>
                    <span className={styles.userInfoLabel}>{t("credit")}</span>
                    <span className={styles.userInfoValue}>{creditDisplayValue}</span>
                </div>
            ) : null}
            <div className={styles.userInfoItem}>
                <span className={styles.userInfoLabel}>{t("totalPL")}</span>
                <span className={styles.userInfoValue}>
                    {totalPLDisplayValue ? totalPLDisplayValue : emptyValue}
                </span>
            </div>
            <div className={styles.userInfoItem}>
                <span className={styles.userInfoLabel}>{t("equity")}</span>
                <span className={styles.userInfoValue}>{equityDisplayValue}</span>
            </div>
            <div className={styles.userInfoItem}>
                <span className={styles.userInfoLabel}>{t("used_funds")}</span>
                <span className={styles.userInfoValue}>
                    <UsedMarginValue />
                </span>
            </div>
            <div className={styles.userInfoItem}>
                <span className={styles.userInfoLabel}>{t("freeMargin")}</span>
                <span className={styles.userInfoValue}>{freeMarginDisplayValue}</span>
            </div>
            <div className={styles.userInfoItem}>
                <span className={styles.userInfoLabel}>{t("marginLevel")}</span>
                <span className={styles.userInfoValue}>{marginLevelDisplayValue}</span>
            </div>
        </div>
    );
};

export const UsedMarginValue: React.FC = React.memo(() => {
    const { isLoggedInUser } = useLoginInfo();
    const { currency: accountCurrency, margin } = useAccountInfo();
    const { leverage: accountLeverage } = useAccountDynamicInfo();
    const { symbols, symbolsInfoById, isQuoteDataLoaded } = useTradeInfo();
    const { openTrades } = useTradeOrders();
    const [usedMargin, setUsedMargin] = useState(null);
    const [isAnyOpenTrades, setIsAnyOpenTrades] = useState(false);

    useEffect(() => {
        setIsAnyOpenTrades(openTrades && openTrades.length > 0);
    }, [openTrades]);

    const recalculateFreeMargin = useCallback(() => {
        if (!isLoggedInUser || !isQuoteDataLoaded) {
            usedMargin && setUsedMargin(null);
            return;
        }
    }, [
        openTrades,
        isQuoteDataLoaded,
        setUsedMargin,
        usedMargin,
        isLoggedInUser,
        accountCurrency,
        accountLeverage,
        symbolsInfoById,
        symbols,
    ]);

    useEffect(() => {
        setUsedMargin(margin);
    }, [margin]);

    useEffect(() => {
        const handle = setInterval(() => recalculateFreeMargin(), 500);

        return () => clearInterval(handle);
    }, [margin]);

    return <>{getUsedMarginDisplayValue({ margin, isAnyOpenTrades, accountCurrency })}</>;
});

UsedMarginValue.displayName = "UsedMarginValue";

const Footer = (): JSX.Element => {
    const dispatch = useDispatch();
    const { isTradingHistoryOpen } = useUI();
    const handleToggleFooter = value => dispatch(setToggleExpandFooter(value));
    const introStep = useIntroStep();

    return (
        <>
            {isTradingHistoryOpen && <TradeHistory />}
            <div
                className={clsx(styles.footer, {
                    [styles.overlayed]: introStep === 6,
                })}>
                <div className={styles.footerLeft}>
                    <UserInfo />
                </div>
                <div className={styles.footerRight}>
                    <FooterToolbar handleToggleFooter={handleToggleFooter} />
                    <ThemeSwitcher />
                </div>
            </div>
        </>
    );
};

export default Footer;

export { default as FooterTablet } from "./components/FooterMobile";

export { default as FooterMobile } from "./components/FooterMobile";
