import React from "react";
import styles from "./Card.module.scss";

export const Card = ({ title, children }: { title: string; children: React.ReactNode }) => {
    return (
        <div className={styles.card}>
            {title}
            {children}
        </div>
    );
};
