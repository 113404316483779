import { useState, useEffect } from "react";

export const TimeIndicator = () => {
    const getTimeString = () => new Date().toLocaleString("en-GB").replace(/\//g, ".");
    const [timeString, setTimeString] = useState(getTimeString());

    useEffect(() => {
        const dateTimer = setInterval(() => {
            setTimeString(getTimeString());
        }, 1000);

        return () => clearInterval(dateTimer);
    }, []);

    return <>{timeString}</>;
};
