import { FC } from "react";
import {
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    Box,
    FormControl,
    Typography,
    createTheme,
    ThemeProvider,
} from "@mui/material";

export enum ImpactLevels {
    All = "All",
    Low = "Low",
    Medium = "Medium",
    High = "High",
}

type Option = {
    value: string;
    label: string;
};

interface IInpactSelectProps {
    onChange: (value: ImpactLevels) => void;
    selectedValue: ImpactLevels;
}

const lightTheme = createTheme({
    palette: {
        mode: "light",
    },
});

const options: Option[] = Object.keys(ImpactLevels).map(option => ({
    label: option,
    value: option,
}));

export const ImpactLevelSelect: FC<IInpactSelectProps> = ({ onChange, selectedValue }) => {
    return (
        <ThemeProvider theme={lightTheme}>
            <Box sx={{ minWidth: 100 }}>
                <Typography sx={{ fontWeight: "700", fontSize: "12px" }}>{"IMPACT"}</Typography>
                <FormControl fullWidth>
                    <Select
                        value={selectedValue}
                        onChange={e => onChange(e.target.value as ImpactLevels)}
                        renderValue={selected => selected}
                        sx={{
                            width: "120px",
                            height: "28px",
                            fontWeight: 700,
                            fontSize: "14px",
                        }}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    borderRadius: "8px",
                                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                                },
                            },
                        }}>
                        {options.map(option => (
                            <MenuItem
                                value={option.label}
                                key={option.value}
                                sx={{
                                    ".MuiTypography-root": {
                                        fontWeight: 700,
                                    },
                                }}>
                                <Checkbox
                                    checked={selectedValue === option.value}
                                    sx={{
                                        height: "32px",
                                        "& .MuiMemuItem-root": {
                                            height: "32px",
                                        },
                                        "& .MuiSvgIcon-root": {
                                            fontSize: 24,
                                            color: selectedValue === option.value ? "#000" : "#9598A2",
                                        },
                                    }}
                                />
                                <ListItemText primary={option.label} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </ThemeProvider>
    );
};
