import React, { useContext } from "react";
import clsx from "clsx";
import { processedQuedOrdersString } from "@/utils/helpers";
import { TradeOrderDispatchContext, TradeOrderStateContext } from "@/contexts/TradeOrder";
import TradeButton, { TradeButtonOperationType } from "@/components/Core/Button/TradeButton/TradeButton";
import { useTradeInfo } from "redux/selectors/tradeInfoSelector";
import { setActiveOperationCategory } from "@/contexts/TradeOrder/actions";
import { useActiveSymbolDynamicFields } from "@/hooks/symbols";
import { TRADE_OPERATION_TYPE } from "@/constants/index";
import useTradeOrderFormData from "@/components/Home/TradingOrderSection/hooks";
import { useQuoteData } from "@/hooks/trade/tradeSocket";
import parentFrameAPI from "@/services/parent";
import { useMarketSchedule } from "@/hooks/trade/schedule";
import { useLoginInfo } from "@/hooks/common/useLoginInfo";
import { useDevice } from "@/redux/selectors/deviceSelector";
import { DevicePlatform } from "@/redux/interfaces/IDevice";

import styles from "./TradingOrderFormMobile.module.scss";

interface ITradingOrderFormProps {
    className?: string;
    isHighlighted: boolean;
}

const TradingOrderFormMobile: React.FC<ITradingOrderFormProps> = ({ className, isHighlighted }) => {
    const tradeOrderDispatch = useContext(TradeOrderDispatchContext);
    const { activeSymbolId, symbolsInfoById } = useTradeInfo();
    const symbolInfo = symbolsInfoById[activeSymbolId];
    const {
        activeOperationCategory,
        isStopLoss,
        isTakeProfit,
        lotSize = symbolInfo?.group?.lotMin || 0.01,
    } = useContext(TradeOrderStateContext);

    const { isLoggedInUser } = useLoginInfo();
    const { placeOrder } = useTradeOrderFormData();
    const { isMarketOpen } = useMarketSchedule(activeSymbolId);
    const { platform } = useDevice();
    const { ask, bid } = useQuoteData(activeSymbolId);
    const {
        isForex: isForexSymbol,
        spreadInPipsDisplayValue,
        spreadInPointsDisplayValue,
    } = useActiveSymbolDynamicFields({ lotSize });

    // REQUEST_PLACED
    const isWithinTenSeconds = () => {
        const time = processedQuedOrdersString.getLast();
        if (!time) return false;
        const timestamp = Number(time);
        const currentTime = Date.now();
        const elapsedTime = currentTime - timestamp;

        return elapsedTime <= 10000;
    };
    const isSellButtonDisabled =
        !isMarketOpen || platform !== DevicePlatform.Desktop
            ? (isStopLoss || isTakeProfit) && activeOperationCategory === "Buy"
            : false;

    const isBuyButtonDisabled =
        !isMarketOpen || platform !== DevicePlatform.Desktop
            ? (isStopLoss || isTakeProfit) && activeOperationCategory === "Sell"
            : false;

    const handlePlaceOrderClick = (action?: TradeButtonOperationType) => {
        return isLoggedInUser ? placeOrder({ actionType: action }) : parentFrameAPI.login();
    };

    return (
        <div className={clsx(styles.buySellButtons, className)}>
            <TradeButton
                key={TRADE_OPERATION_TYPE.SELL}
                symbolId={activeSymbolId}
                showFlashButton={isHighlighted}
                operation={TRADE_OPERATION_TYPE.SELL}
                active={true}
                onClick={() => {
                    tradeOrderDispatch(setActiveOperationCategory(TRADE_OPERATION_TYPE.SELL, ask, bid));
                    handlePlaceOrderClick(TRADE_OPERATION_TYPE.SELL);
                }}
                disabled={isSellButtonDisabled || isWithinTenSeconds()}
                classes={{
                    btn: clsx(styles.btn),
                    label: styles.label,
                    value: styles.value,
                    buy: styles.buy,
                    sell: styles.sell,
                }}
            />
            <TradeButton
                key={TRADE_OPERATION_TYPE.BUY}
                symbolId={activeSymbolId}
                showFlashButton={isHighlighted}
                operation={TRADE_OPERATION_TYPE.BUY}
                active={true}
                onClick={() => {
                    tradeOrderDispatch(setActiveOperationCategory(TRADE_OPERATION_TYPE.BUY, ask, bid));
                    handlePlaceOrderClick(TRADE_OPERATION_TYPE.BUY);
                }}
                disabled={isBuyButtonDisabled || isWithinTenSeconds()}
                classes={{
                    btn: clsx(styles.btn),
                    label: styles.label,
                    value: styles.value,
                    buy: styles.buy,
                    sell: styles.sell,
                }}
            />

            {spreadInPipsDisplayValue && (
                <div
                    className={clsx(styles.pipsValue, {
                        [styles.tablet]: platform === DevicePlatform.Tablet,
                    })}>
                    {isForexSymbol ? spreadInPipsDisplayValue : spreadInPointsDisplayValue}
                </div>
            )}
        </div>
    );
};

export default React.memo(TradingOrderFormMobile);
