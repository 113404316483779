import { FC } from "react";
import { Select, MenuItem, Box, FormControl, createTheme, ThemeProvider } from "@mui/material";

interface ICountriesSelectProps {
    onChange: (value: CountryCodes) => void;
    selectedValue: CountryCodes;
}

const lightTheme = createTheme({
    palette: {
        mode: "light",
    },
});
export enum CountryCodes {
    USA = "us",
    CANADA = "ca",
    GERMANY = "de",
    AUSTRALIA = "au",
    SPAIN = "es",
    SINGAPORE = "sg",
    EUROZONE = "ez",
    HONGKONG = "hk",
    JAPAN = "jp",
    ITALY = "it",
    FRANCE = "fr",
}

const options = [
    { label: "US", value: CountryCodes.USA },
    { label: "Canada", value: CountryCodes.CANADA },
    { label: "Germany", value: CountryCodes.GERMANY },
    { label: "Australia", value: CountryCodes.AUSTRALIA },
    { label: "Spain", value: CountryCodes.SPAIN },
    { label: "Singapore", value: CountryCodes.SINGAPORE },
    { label: "Euro Zone", value: CountryCodes.EUROZONE },
    { label: "Hong Kong", value: CountryCodes.HONGKONG },
    { label: "Japan", value: CountryCodes.JAPAN },
    { label: "Italy", value: CountryCodes.ITALY },
    { label: "France", value: CountryCodes.FRANCE },
];

export const CountrySelect: FC<ICountriesSelectProps> = ({ onChange, selectedValue }) => {
    return (
        <ThemeProvider theme={lightTheme}>
            <Box>
                <FormControl fullWidth>
                    <Select
                        value={selectedValue}
                        onChange={e => onChange(e.target.value as CountryCodes)}
                        renderValue={selected => (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}>
                                <img
                                    src={`/images/countries-flag/${selected}.svg`}
                                    width={20}
                                    height={20}
                                    alt="flag"
                                />
                            </Box>
                        )}
                        sx={{
                            minWidth: "52px",
                            height: "28px",
                        }}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    transform: "translateX(-20px)",
                                    borderRadius: "8px",
                                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                                },
                            },
                        }}>
                        {options.map(option => (
                            <MenuItem
                                value={option.value}
                                sx={{ fontWeight: 700, fontSize: "10px", gap: "5px" }}
                                key={option.value}>
                                <img
                                    src={`/images/countries-flag/${option.value}.svg`}
                                    width={20}
                                    height={20}
                                />
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </ThemeProvider>
    );
};
