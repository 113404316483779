import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { EconomicCalendarItem } from "./EconomicCalendarItem";
import { ImpactLevelSelect } from "../ImpactLevelSelect";
import { CountrySelect } from "../CountrySelect";
import CloseIcon from "@mui/icons-material/Close";
import { useEconomicCalendar } from "../../useEconomicCalendar";
import styles from "./EconomicCalendar.module.scss";

export const EconomicCalendar = ({ setOpen }: { setOpen: (value: boolean) => void }) => {
    const {
        data,
        selectedCountry,
        selectedImpactLevel,
        setCountry,
        setImpactLevel,
    } = useEconomicCalendar();

    return (
        <div className={styles.container}>
            <CloseIcon onClick={() => setOpen(false)} className={styles.closeIcon} />
            <div className={styles.filters}>
                <div className={styles.demo}>Demo (not real data)</div>
                <ImpactLevelSelect onChange={setImpactLevel} selectedValue={selectedImpactLevel} />
                <CountrySelect onChange={setCountry} selectedValue={selectedCountry} />
            </div>
            <div className={styles.content}>
                <div className={styles.headers}>
                    <div className={styles.tab}>Actual</div>
                    <div className={styles.tab}>Estimate</div>
                    <div className={styles.tab}>Previous</div>
                </div>
                <div className={styles.itemsList}>
                    <PerfectScrollbar>
                        {Object.keys(data).map((timestamp, index) => (
                            <div className={styles.block} key={index}>
                                <div className={styles.datetime}>
                                    {moment.utc(timestamp).format("dddd, MMMM D, YYYY")}
                                </div>
                                {data[timestamp].map((item, _index) => (
                                    <EconomicCalendarItem data={item} key={`${_index}-${item.title}}`} />
                                ))}
                            </div>
                        ))}
                    </PerfectScrollbar>
                </div>
            </div>
        </div>
    );
};
