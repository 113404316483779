import { FC } from "react";
import styles from "./TimeframesPanel.module.scss";
import clsx from "clsx";

export enum TimeFrames {
    M1 = "1m",
    M5 = "5m",
    M15 = "15m",
    M30 = "30m",
    H1 = "1h",
    H4 = "4h",
    D1 = "1d",
    W1 = "1w",
    MON1 = "1M",
}

interface ITimeframesPanelProps {
    onChange: (timeframe: TimeFrames) => void;
    selected: TimeFrames;
}

export const TimeframesPanel: FC<ITimeframesPanelProps> = ({ onChange, selected }) => {
    return (
        <div className={styles.timeFrames}>
            {Object.values(TimeFrames).map((timeframe, index) => (
                <div
                    onClick={() => onChange(timeframe)}
                    key={index}
                    className={clsx(styles.item, {
                        [styles.selected]: selected === timeframe,
                    })}>
                    <span>{timeframe.slice(0, timeframe.length - 1)}</span>
                    <span>
                        <sup>{timeframe.slice(-1)}</sup>
                    </span>
                </div>
            ))}
        </div>
    );
};
